<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Ro'yxatdan o'tish</h3>
          </div>
        </div>

        <div class="card-body">
          <v-row>
            <v-col cols="4">
              <v-text-field
                :error-messages="usernameErrors"
                outlined
                label="Username"
                v-model="username"
                @input="$v.username.$touch()"
                @blur="$v.username.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :error-messages="passwordErrors"
                outlined
                type="password"
                label="Parol"
                v-model="password"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :error-messages="re_passwordErrors"
                outlined
                type="password"
                label="Parolni tasdiqlang"
                @input="$v.re_password.$touch()"
                @blur="$v.re_password.$touch()"
                v-model="re_password"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :error-messages="firstErrors"
                outlined
                label="Ismi"
                v-model="first"
                @input="$v.first.$touch()"
                @blur="$v.first.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :error-messages="lastErrors"
                outlined
                label="Familiya"
                v-model="last"
                @input="$v.last.$touch()"
                @blur="$v.last.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :error-messages="date_birthErrors"
                    v-model="date_birth"
                    label="Tug'ilgan sana"
                    append-icon="event"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @input="$v.date_birth.$touch()"
                    @blur="$v.date_birth.$touch()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_birth"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :error-messages="innErrors"
                outlined
                label="INN"
                v-model="inn"
                counter="9"
                v-mask="'#########'"
                @input="$v.inn.$touch()"
                @blur="$v.inn.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :error-messages="passport_numErrors"
                outlined
                label="Passport raqami"
                v-model="passport_num"
                counter="9"
                v-mask="'AA#######'"
                @input="$v.passport_num.$touch()"
                @blur="$v.passport_num.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :error-messages="passport_addressErrors"
                outlined
                label="Passport olingan adress"
                v-model="passport_address"
                @input="$v.passport_address.$touch()"
                @blur="$v.passport_address.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :error-messages="phoneNumberErrors"
                outlined
                label="Telefon raqam"
                v-model="phoneNumber"
                append-icon="mdi-plus-box"
                @click:append="addPhoneToList"
                v-mask="'### (##) ### ## ##'"
                @input="$v.phoneNumber.$touch()"
                @blur="$v.phoneNumber.$touch()"
              ></v-text-field>
            </v-col>
            <v-col
              cols="3"
              v-for="(number, index) in returnPhoneNumber"
              :key="'number' + index"
            >
              <v-text-field
                outlined
                label="Telefon raqam"
                :value="number.phone_number"
                append-icon="mdi-delete"
                @click:append="removePhoneNumber(index)"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
              >
                Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      menu2: false,
      username: '',
      password: '',
      re_password: '',
      groups: [1],
      phoneNumber: '',
      phoneNumberList: [],
      first: '',
      last: '',
      date_birth: '',
      inn: '',
      passport_num: '',
      passport_address: ''
    }
  },
  validations: {
    username: {
      required
    },
    password: {
      required,
      minLength: minLength(8)
    },
    re_password: {
      sameAsPassword: sameAs('password')
    },
    phoneNumber: {
      required
    },
    first: {
      required
    },
    last: {
      required
    },
    date_birth: {
      required
    },
    inn: {
      required
    },
    passport_num: {
      required
    },
    passport_address: {
      required
    }
  },
  computed: {
    returnPhoneNumber() {
      return this.phoneNumberList
    },
    passport_addressErrors() {
      const errors = []
      if (!this.$v.passport_address.$dirty) return errors

      !this.$v.passport_address.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    passport_numErrors() {
      const errors = []
      if (!this.$v.passport_num.$dirty) return errors

      !this.$v.passport_num.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    innErrors() {
      const errors = []
      if (!this.$v.inn.$dirty) return errors

      !this.$v.inn.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    date_birthErrors() {
      const errors = []
      if (!this.$v.date_birth.$dirty) return errors

      !this.$v.date_birth.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    lastErrors() {
      const errors = []
      if (!this.$v.last.$dirty) return errors

      !this.$v.last.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    firstErrors() {
      const errors = []
      if (!this.$v.first.$dirty) return errors

      !this.$v.first.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    phoneNumberErrors() {
      const errors = []
      if (!this.$v.phoneNumber.$dirty) return errors
      !this.$v.phoneNumber.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    re_passwordErrors() {
      const errors = []
      if (!this.$v.re_password.$dirty) return errors
      !this.$v.re_password.sameAsPassword &&
        errors.push('Parol mos bolishi kerak')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    usernameErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  watch: {
    username: function (val) {
      this.username = val.toLowerCase()
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.username))
        this.username = this.username.replace(/\s/g, '')
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Haydovchilar ruyhati' }])
  },
  methods: {
    addPhoneToList() {
      const data = {
        phone_number: this.phoneNumber
      }
      this.phoneNumberList.push(data)
      this.phoneNumber = ''
    },
    removePhoneNumber(payload) {
      this.phoneNumberList.splice(payload, 1)
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        if (this.phoneNumber !== '') {
          const data = {
            phone_number: this.phoneNumber
          }
          this.phoneNumberList.push(data)
        }
        const data = {
          user_info: {
            username: this.username,
            password: this.password,
            re_password: this.re_password,
            groups: this.groups
          },
          driver_phone: [...this.phoneNumberList],
          first: this.first,
          last: this.last,
          date_birth: this.date_birth,
          inn: this.inn,
          passport_num: this.passport_num,
          passport_address: this.passport_address
        }
        this.$store.dispatch('createDriver', data)
        this.$v.$reset()
      }
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
  display: none;
}
.form-group--error {
  display: block;
}
</style>
